import { default as cartltOYTvmzvgMeta } from "/opt/buildhome/repo/pages/cart.vue?macro=true";
import { default as customerDGTc9fo7eyMeta } from "/opt/buildhome/repo/pages/customer.vue?macro=true";
import { default as error5bJL3YgCAEMeta } from "/opt/buildhome/repo/pages/error.vue?macro=true";
import { default as paymentt9BiBTWFpLMeta } from "/opt/buildhome/repo/pages/payment.vue?macro=true";
import { default as shippingFRNo84PUVxMeta } from "/opt/buildhome/repo/pages/shipping.vue?macro=true";
import { default as successBVIqjShRzzMeta } from "/opt/buildhome/repo/pages/success.vue?macro=true";
import { default as cartG56hCyeg5uMeta } from "~/pages/cart.vue?macro=true";
export default [
  {
    name: cartltOYTvmzvgMeta?.name ?? "cart",
    path: cartltOYTvmzvgMeta?.path ?? "/cart",
    meta: cartltOYTvmzvgMeta || {},
    alias: cartltOYTvmzvgMeta?.alias || [],
    redirect: cartltOYTvmzvgMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: customerDGTc9fo7eyMeta?.name ?? "customer",
    path: customerDGTc9fo7eyMeta?.path ?? "/customer",
    meta: customerDGTc9fo7eyMeta || {},
    alias: customerDGTc9fo7eyMeta?.alias || [],
    redirect: customerDGTc9fo7eyMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/customer.vue").then(m => m.default || m)
  },
  {
    name: error5bJL3YgCAEMeta?.name ?? "error",
    path: error5bJL3YgCAEMeta?.path ?? "/error",
    meta: error5bJL3YgCAEMeta || {},
    alias: error5bJL3YgCAEMeta?.alias || [],
    redirect: error5bJL3YgCAEMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/error.vue").then(m => m.default || m)
  },
  {
    name: paymentt9BiBTWFpLMeta?.name ?? "payment",
    path: paymentt9BiBTWFpLMeta?.path ?? "/payment",
    meta: paymentt9BiBTWFpLMeta || {},
    alias: paymentt9BiBTWFpLMeta?.alias || [],
    redirect: paymentt9BiBTWFpLMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: shippingFRNo84PUVxMeta?.name ?? "shipping",
    path: shippingFRNo84PUVxMeta?.path ?? "/shipping",
    meta: shippingFRNo84PUVxMeta || {},
    alias: shippingFRNo84PUVxMeta?.alias || [],
    redirect: shippingFRNo84PUVxMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/shipping.vue").then(m => m.default || m)
  },
  {
    name: successBVIqjShRzzMeta?.name ?? "success",
    path: successBVIqjShRzzMeta?.path ?? "/success",
    meta: successBVIqjShRzzMeta || {},
    alias: successBVIqjShRzzMeta?.alias || [],
    redirect: successBVIqjShRzzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/success.vue").then(m => m.default || m)
  },
  {
    path: cartG56hCyeg5uMeta?.path ?? "/",
    name: cartG56hCyeg5uMeta?.name ?? "init",
    meta: cartG56hCyeg5uMeta || {},
    alias: cartG56hCyeg5uMeta?.alias || [],
    redirect: cartG56hCyeg5uMeta?.redirect || undefined,
    component: () => import("~/pages/cart.vue").then(m => m.default || m)
  }
]