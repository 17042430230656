export default defineNuxtPlugin((nuxtApp) => {
    const { $toast } = useNuxtApp();

    nuxtApp.vueApp.config.errorHandler = (error: any) => {
        let errorMsg;
        console.log(error);

        if (typeof error === "string") {
            errorMsg = error;
        } else {
            errorMsg = error.message ?? '';
        }

        if (errorMsg.includes('qty exceeds the maximum qty')) {
            errorMsg = "Cannot add more products"
        }

        errorMsg = nuxtApp.vueApp.config.globalProperties.$t(errorMsg);

        if (errorMsg) {
            $toast.error(errorMsg)
        } else {
            console.error(error);
        }
    };
});
